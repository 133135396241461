//.title_branding {}

	.branding_logo {

		display: block;
		width: 100%;
		max-width: 220px;
		margin: 0 auto;
		@include transition(all ease-in-out 200ms);

		#outline { fill:#fff }
		#state { fill: $color-primary; @include transition(all ease-in-out 200ms); }
		#plumbers { fill: $color-secondary; }

		@include media-breakpoint-up(lg) {
			margin-left: 0;
		}

		&:hover {
			#state {
				fill: lighten($color-primary, 9%);
				@include transition(all ease-in-out 200ms);
			}
		}

	}